import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { get, sortBy } from 'lodash';
import { SEO } from '../components/common/seo';
import { getSchema } from '../common/schema';
import { ArticleList } from '../components/article/list';
import { parseArticles } from '../common/content';
import { ArticleDynamicList } from '../components/article/dynamicList';
import { ArticleListLayout } from '../components/article/list/list.types';
import { useDictionary } from '../context/dictionary';
import { HeadingSize, HeadingVariant } from '../components/common/heading/heading.types';

const PAGE_SIZE = 2;

export const query = graphql`
  query CategoryQuery($id: String!, $originalId: String!, $initialSize: Int!) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsCategory(
      id: { eq: $id }
    ) {
      model {
        apiKey
      }
      meta {
        ...PageMeta
      }
      seo {
        ...PageSEO
      }
      locale
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      name
    }
    versions: allDatoCmsCategory(
      filter: {slug: {ne: null}, originalId: {eq: $originalId}}
    ) {
      nodes {
        slug
        locale
        model {
          apiKey
        }
      }
    }
    articles: allDatoCmsArticle(
      filter: {slug: {ne: null}, category: {id: {eq: $id}}},
      sort: {fields: meta___firstPublishedAt, order: DESC},
      limit: $initialSize
    ) {
      nodes {
        ...ArticleCard
      }
      totalCount
    }
  }
`;

const Category = ({ data, pageContext }: any) => {
  const settings = get(data, 'settings.siteMetadata');
  const page = get(data, 'page');
  const site = get(data, 'site');
  const versions = get(data, 'versions.nodes');

  const articles = get(data, 'articles.nodes');
  const articlesTotalCount = get(data, 'articles.totalCount',0);

  const labelMore = useDictionary("labelMore");

  if (!page || !settings || !site) return null;

  const schema = getSchema({ settings, site, page, pageContext });
 
  return (
    <Fragment>
      <SEO 
        tags={page.seoMetaTags}
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
        schema={schema}
        isArticle={false}
        page={page}
        versions={versions}
      />
      <ArticleDynamicList heading={page.name} headingSize={HeadingSize.LARGE} headingVariant={HeadingVariant.OVERLINE} buttonLabel={labelMore} items={parseArticles(articles)} layout={ArticleListLayout.SINGLE} excludeOriginalIds={articles.map(a => a.originalId)} categoryOriginalId={pageContext.originalId} total={Math.max(articlesTotalCount - pageContext.initialSize,0)} paginationSize={pageContext.paginationSize} />
    </Fragment>
  );
};

export default Category;